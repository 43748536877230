<template>
  <div class="links-wrapper">
    <a v-for="(linkItem,index) in links" v-bind:key="index" :href="linkItem.link" target="_blank">{{ linkItem.text }}</a>
  </div>
</template>

<script>
import jsonLinks from '../data/links.json'

export default {
  name: 'LinkMenu',
  data () {
    return {
      links: jsonLinks
    }
  }
}
</script>

<style scoped lang="scss">
.links-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 20px auto 40px auto;
  a {
    color: white;
    background: #233c7e;
    border: 1px solid #233c7e;
    border-radius: 10px;
    width: 75%;
    padding: 1.2rem;
    margin: 1rem auto 1rem auto;
    display: block;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: bold;
    &:hover{
      background: #DD961F;
    }
  }
}
</style>

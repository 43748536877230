<template>
  <div id="pwa-app">
    <div class="main-container">
      <img alt="RCA logo" src="img/logo.svg" class="logo">
      <LinkMenu />
      <Footer />
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import LinkMenu from './components/LinkMenu'
import Footer from './components/Footer'

export default {
  name: 'app',
  components: {
    LinkMenu,
    Footer
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#pwa-app {
  background: url('assets/home-bg.jpg');
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-size: cover;
  background-position-x: 50%;
  padding-top: 20px;
  &::before{
    content: "";
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    left: 0;
  }
  .main-container{
    position: relative;
    z-index: 3;
  }
  a {
    text-decoration: none;
  }
  .logo {
    height: 70px;
    background: #233c7e;
    padding: 20px;
  }
  .install-btn{
    background: red;
    cursor: pointer;
  }
}
</style>

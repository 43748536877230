<template>
  <div class="footer">
    <a href="https://www.linkedin.com/company/restaurant-%26-catering-industry-association/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
    <a href="https://twitter.com/RestCatering" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
    <a href="https://www.facebook.com/RestaurantandCatering/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
    <a href="https://www.youtube.com/user/restncat/" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" /></a>
    <a href="https://www.instagram.com/restcatering/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
    <a href="https://www.rca.asn.au/user" target="_blank" class="login-link">Login or Signup</a>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
.footer {
  height: 100px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  a {
    font-size: 1.2rem;
    text-transform: uppercase;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    color: #233c7e;
    &.login-link{
      float: right;
      font-weight: bold;
      padding: 2px 10px;
    }
  }
}
</style>
